import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  listRequirements = new BehaviorSubject<any[]>(null);
  listRequirements$ = this.listRequirements.asObservable();

  listDocuments = new BehaviorSubject(null);
  listDocuments$ = this.listDocuments.asObservable();

  listRevisionDocuments = new BehaviorSubject(null);
  listRevisionDocuments$ = this.listRevisionDocuments.asObservable();

  numDocsRequired = new BehaviorSubject(null);
  numDocsRequired$ = this.numDocsRequired.asObservable();

  numDocs = new BehaviorSubject(null);
  numDocs$ = this.listDocuments.asObservable();

  missingDocsRequired  = new BehaviorSubject(null);
  missingDocsRequired$ = this.missingDocsRequired.asObservable();

  constructor(private http: HttpClient) {
  }

  setListDocuments(document: any) {
    this.listDocuments.next(document);
  }

  setMissingDocsRequired(){
    this.missingDocsRequired.next(this.numDocs.value < this.numDocsRequired.value);
  }

  setNumDocsRequired(){
    this.numDocsRequired.next(0);
    if(this.listRequirements && this.listRequirements.value != null && this.listRequirements.value.length > 0){
      this.numDocsRequired.next((this.listRequirements.value.filter(requirement => requirement.required == true)).length);
    }
  }

  setNumDocs(){
    this.numDocs.next(0);
    if(this.listDocuments && this.listDocuments.value != null && this.listDocuments.value.length > 0){
      this.numDocs.next(this.listDocuments.value.length);
    }
  }

  getDocumentsByRequest(idRequest: string): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }/api/Document/getAllDocumentsRequest/${ idRequest }`).pipe(map(resp => {
      return resp.map(doc => {
        return {
          idDocument:doc.idDocument,
          idReqdocument: doc.idReqdocument,
          statepassedDocument: 0,
          nameDocument: doc.nameDocument,
          observationDocument : "",
          stateDocument: doc.stateDocument,
          fileDocument: doc.fileDocument
        }
      });
    }));
  }

  getVersionsDocument(json: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Document/getAllVersionDocuments`, json, {responseType:'text'});
  }

  /**
   * Obtiene las versiones de un doc requisito
   * @param idRequest ID de la solicitud
   * @param idReqDoc ID del documento requisito
   */
  getVersionsForDocuments(idRequest: number, idReqDoc: any,dignatario:any,sede:any): Observable<any[]> {
    return this.http.get<any[]>(`${ environment.apiUrlFuncionario }/validationApi/Request/GetVersionDocument/${ idRequest }/${ idReqDoc }?dignatary=${ dignatario }&sede=${ sede }`);
  }

  saveRequestDocuments(json: any): Observable<any> {
    return this.http.post(`${environment.apiUrlFuncionario}/validationApi/Document/insertDocument`, json, {responseType:'text'});
  }

  saveExistenceCertificate(json: any): Observable<any> {
    return this.http.post(`${environment.apiUrlFuncionario}/validationApi/CertificateExist/insertExistenceCertificate`, json, {responseType:'text'});
  }

  getExistenceCertificate(idRequest: string): Observable<any> {
    return this.http.get(`${ environment.apiUrlFuncionario }/validationApi/CertificateExist/getExistenceCertificate/${ idRequest }`, {responseType:'text'});
  }
}
