// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //Back localhost
  //apiUrl: 'https://localhost:5001',
  //apiUrlFuncionario: 'https://localhost:5001',

  //Back Azure
  apiUrl: 'https://apm-aeu-sds-dev-shared.azure-api.net/Tramites-Recoperjuridica-Ciu',
  //apiUrl: 'https://app-sds-recoperjuridica-ciu-dev-eastus.azurewebsites.net',
  //apiUrl: 'https://localhost:5001',
  apiUrlLocal: 'https://localhost:5001',
  apiUrlFuncionario: 'https://apm-aeu-sds-dev-shared.azure-api.net/Tramites-Recoperjuridica-Fun',
  //apiUrlFuncionario: 'https://app-sds-recoperjuridica-fun-dev-eastus.azurewebsites.net',
  //apiUrlFuncionario: 'https://localhost:5001',
  PROCEDURE_SECURITY_URI: 'https://apm-aeu-sds-dev-shared.azure-api.net/security/api/v2/Security',
  PROCEDURE_SHARED_URI: 'https://apm-aeu-sds-dev-shared.azure-api.net/tramites-shared/api',



//qa
  //apiUrl: 'https://app-sds-recoperjuridica-ciu-release-eastus.azurewebsites.net',
  //apiUrlFuncionario: 'https://app-sds-recoperjuridica-fun-release-eastus.azurewebsites.net',


  procedureName : 'Inscripción de dignatarios de ESAL',
  procedureFullName : 'Inscripción de dignatarios de fundaciones, corporaciones y/o asociaciones de utilidad común y/o sin animó de lucro',
  procedureDescription : 'Obtener la identificación legal para ejercer derechos y contraer obligaciones y de ser representadas judicial y extrajudicialmente, las asociaciones o corporaciones y fundaciones o instituciones de utilidad común que tengan su domicilio principal en la jurisdicción del ente territorial.',
  numProcedure : '6',
  idProcedureDBCommmons : "9D19364A-E593-4405-AFE2-6887941257C1",
  nomDocCertificadoExistencia: 'CertificadoExistencia',
  tiempoDeAtencion : 31,

  VUDTS_URL: 'https://dev.ventanillavirtualtramites.saludcapital.gov.co',
  VUDTS_URL_LOCAL: 'localhost:4200',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
