import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { Request, Workers } from '@models';
import { BehaviorSubject, catchError, lastValueFrom, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { SSOAuthResponse } from "@models";
import { IApproveCollaborator } from "../models/approve-collaborator.model";
import { map } from "rxjs/operators";
import { SaveRequestInDTO } from '../models/saveRequestDTO.model';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  requestNumRadicado = new BehaviorSubject(null);
  requestNumRadicado$ = this.requestNumRadicado.asObservable();

  requestSelected = new BehaviorSubject(null);
  requestSelected$ = this.requestSelected.asObservable();

  getRequest = new BehaviorSubject(false);
  getRequest$ = this.getRequest.asObservable();

  viewRequest = new BehaviorSubject(false);
  viewRequest$ = this.viewRequest.asObservable();

  AssemblyMinutes = new BehaviorSubject(null);
  AssemblyMinutes$ = this.AssemblyMinutes.asObservable();

  ResponseResolution = new BehaviorSubject(null);
  ResponseResolution$ = this.ResponseResolution.asObservable();

  resetRequest: Subject<boolean> = new ReplaySubject<boolean>(1)
  resetRequest$ = this.resetRequest.asObservable();

  VisitSchedule = new BehaviorSubject(null);
  VisitSchedule$ = this.VisitSchedule.asObservable();

  VisitScheduleOne = new BehaviorSubject(null);
  VisitScheduleOne$ = this.VisitScheduleOne.asObservable();


  //@todo -> tmp quitar despues
  statusRequest: any[] = [
    'Solicitud creada por usuario externo',
    'Solicitud enviada por usuario externo',
    'Solicitud cancelada'
  ];

  constructor(private http: HttpClient) {
  }

  //API CIUDADANO

   /**
   * Registra el historial de cambios de una solicitud
   * @param data data de la auditoria
   */
   insertAuditory(data: any) {
    return this.http.post<any>(`${ environment.apiUrl }/api/Auditory/insertAuditory`, data);
  }

  saveRequest(json: SaveRequestInDTO): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }/api/Request/insertRequestT6`, json);
  }




  getRequestById(id): Observable<any> {
    return this.http.get<Request>(`${ environment.apiUrl }/api/Request/getRequestByIdRequest/${ id }`);
  }

  crearNumeroRadicado(json: any): Observable<any> {
    return this.http.put(`${ environment.apiUrl }/api/Request/updateFiledNumberT6`, json, {responseType:'text'});
  }

  //API FUNCIONARIO

  /*getRequestsByRol(idProcedure : string, idRole : string, isStateActivityBefore: string): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrlFuncionario }/validationApi/Request/GetAllRequestByRol/${ idRole }/${ idProcedure }?IsStateActivityBefore=${isStateActivityBefore}`).pipe(
      map(response => {
        return response.map(item => {
          let fechaString = item.dateRequest;
          let fecha: moment.Moment;
          let diasPasados: number;
          const tiempoDeAtencion : number = environment.tiempoDeAtencion;
          let tiempoAtencionRestante : number;
          fecha = moment(fechaString);
          const fechaActual = moment();
          diasPasados = fechaActual.diff(fecha, 'days');
          tiempoAtencionRestante = tiempoDeAtencion - diasPasados >= 0 ? tiempoDeAtencion - diasPasados : 0

          return {
            ...item,
            semaforo: tiempoAtencionRestante,
            flag: this.getColorFlag(tiempoAtencionRestante)
          };
        });
      })
    );
  }*/


  getRequestsByUserExternal(json: any): Observable<any> {


    return this.http.post<any>(`${environment.apiUrl}/api/Request/getRequestByIdProcedureAndIdUserExternal`,json).pipe(
      map(response => {

        return response.map(async item => {

          const fechaSolicitud = moment(item.dateRequest).format('YYYY-MM-DD');

          let resp = await lastValueFrom(this.calculateRemainingDays(fechaSolicitud, environment.idProcedureDBCommmons));

          let diasRestantes = resp.data.personaJuridica.diasRestantes;

          return {
            ...item,
            semaforo: diasRestantes < 0 ? 0 : diasRestantes,
            flag: this.getColorFlag(diasRestantes)
          };
        });
      })
    );


  }

  validatefirstrequest(json: any): Observable<any>
  {
    return this.http.post<any>(`${environment.apiUrl}/api/Request/getRequestByIdProcedureAndIdUserExternal`,json);
  }


  getRequestsByRol(idProcedure : string, idRole : string, isStateActivityBefore: string): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrlFuncionario }/validationApi/Request/GetAllRequestByRol/${ idRole }/${ idProcedure }?IsStateActivityBefore=${isStateActivityBefore}`).pipe(
      map(response => {

        return response.map(async item => {

          const fechaSolicitud = moment(item.dateRequest).format('YYYY-MM-DD');

          let resp = await lastValueFrom(this.calculateRemainingDays(fechaSolicitud, environment.idProcedureDBCommmons));

          let diasRestantes = resp.data.personaJuridica.diasRestantes;

          return {
            ...item,
            semaforo: diasRestantes < 0 ? 0 : diasRestantes,
            flag: this.getColorFlag(diasRestantes)
          };
        });
      })

    );
  }








  getColorFlag(tiempoAtencionRestante){
    const tiempoDeAtencion : number = environment.tiempoDeAtencion;
    if (tiempoAtencionRestante > (tiempoDeAtencion / 2)) {
      return "green";
    }
    return tiempoAtencionRestante < (tiempoDeAtencion / 4) ? "red" : "yellow";
  }

  calculateRemainingDays(initialDate, idTramite): Observable<any> {
    return this.http.get<Request>(`${ environment.PROCEDURE_SHARED_URI }/v1/Holiday/CalculateRemainingDays/${ initialDate }/${ idTramite }`).pipe(map(resp => {
      return resp;
    }))
  }

  assignRequest(json: any): Observable<any> {
    return this.http.put<any>(`${ environment.apiUrlFuncionario }/validationApi/Request/UpdateUserInternal`, json);
  }

  updateStateRequest(json: any): Observable<any> {
    return this.http.put(`${ environment.apiUrlFuncionario }/validationApi/Request/updateStateRequest`, json, {responseType:'text'});
  }


  /**
   * Obtiene el rol de un usuario dado su oid del b2c
   * @param oid Id dado por el b2c del usuario a buscar
   */
  getRoleByIdUser(oid: string) : Observable<any> {
    return this.http.get(`${environment.PROCEDURE_SECURITY_URI}/GetRoleByIdUser/${oid}`);
  }
  /**
   * Obtiene la informacion de un usuario dado su codigo de ventanilla
   * @param idCode Codigo de ventanilla a buscar
   */
  public getInfoUserByIdCodeVentanilla(idCode: any): Observable<any> {
    return this.http.get(`${environment.PROCEDURE_SHARED_URI}/v2/Persona/GetInfoUserById/${idCode}`);
  }
  /**
   * Obtiene codigo de ventanilla de un usuario dado su oid del B2C
   * @param oidToken_usuario Id dado por el b2c
   */
  public getCodeVentanillaByIdUser(oidToken_usuario: string) : Observable<any> {
    return this.http.get(`${environment.PROCEDURE_SECURITY_URI}/GetCodeVentanillaByIdUser/${oidToken_usuario}`);
  }
  /**
   * Obtiene lista de Tipos de Identificacion
   */
  public getIdentificationType(): Observable<any>{
    return this.http.get(`${environment.PROCEDURE_SHARED_URI}/v1/TipoIdentificacion/GetTipoIdentificacion`);
  }

  /**
   * Metodo encargado de retornar las solicitudes para el reporte
   * @param reportType
   * @param pageIndex
   * @param pageSize
   * @param typeSearch
   * @param filters
   * @param consulta ['', 'descarga']
   */
  getRequestsForReportDashboard(reportType: number, pageIndex: number, pageSize: number, typeSearch: any, filters: any, consulta: string = '') {
    let params = new HttpParams()
      .append('filterType', typeSearch != '0' ? typeSearch : '')
      .append('consulta', `${ consulta }`)
      .append('pagenumber', `${ pageIndex }`)
      .append('pagesize', `${ pageSize }`);
    if (typeSearch != 'fecha') {
      params = params.append('filter', filters?.filter?.toString()?.trim() ?? '')
    }

    if (typeSearch == 'fecha') {
      params = params.append('dateinitial', `${ moment(filters?.startDate).format('YYYY-MM-DD') }`)
        .append('datefinal', `${ moment(filters?.endDate).format('YYYY-MM-DD') }`)
    }
    if (reportType == 1 ) {
      return this.http.get(`${ environment.apiUrlFuncionario }/validationApi/Reports/GetReportEsal`, { params })
        .pipe(
          map((respData: any) => {
            return {
              data: respData.data.map(item => {
                // sol creada de solicitud - sol cancelada por sistema
                item.radicado = (item.estado.toLowerCase().includes('creada por usuario') || item.estado.toLowerCase().includes('cancelada por sistema')) ? 'No Disponible' : item.radicado;
                return item;
              }),
              total: respData.count
            }
          }), catchError(() => of({ data: [], total: 10 }))
        )
    } else {
      return this.http.patch(`${ environment.apiUrlFuncionario }/validationApi/Reports/GetReportDignataries`, {}, { params })
        .pipe(
          map((respData: any) => {
            return { data: respData.data, total: respData.count };
          }), catchError(() => of({ data: [], total: 10 }))
        )
    }
  }
  /**
   * Obtiene el formato de notificacion
   * @param IdStateRequest  ID del estado de la solicitud
   * @param IdProcedure  ID del tramite
   */
  getFormats(IdStateRequest : number, IdProcedure : any): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrlFuncionario }/validationApi/Document/GetFormats/${ IdStateRequest }/${ IdProcedure }`);
  }


}
